import { useState, useEffect } from "react";
import config from "../../../config";
import axios from "axios";
import dateFormat from "dateformat";
import useUsers from "./../Users/useUsers";

function useOtherAccounts() {
  const { loginSession, user } = useUsers();

  console.log(user);

  const [otherAccounts, setAccountAccounts] = useState([]);
  const [otherAccount, setAccountAccount] = useState([]);
  const [telemarketerOA, setTeleMarketerOA] = useState([]);
  const [callLogs, setCallLogs] = useState([]);
  const [todayScheduled, setTodayScheduled] = useState([])

  function getTelemarketerOA() {
    if (loginSession.length != 0) {
      axios
        .post(`${config.host}:${config.port}/api/v2/account_executives`)
        .then(function (response) {
          // console.log(response);
          let data = response.data;
          setTeleMarketerOA(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getOtherAccounts() {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/sales_accounts/accounts_list`
        )
        .then(function (response) {
          let data = response.data;
          setAccountAccounts(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getScheduledToday(user) {
    if (loginSession.length != 0 && user.length !== 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/sales_accounts/accounts_by_today`,{
            today: dateFormat(new Date(), "dddd"),
            ae_name: user.full_name
          }
        )
        .then(function (response) {
          console.log(response);
          let data = response.data;
          setTodayScheduled(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getOtherAccountCallByID(client_id) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/sales_accounts/account_callLogs_by_id`,
          {
            user: loginSession.username,
            client_id: client_id,
          }
        )
        .then(function (response) {
          let data = response.data
            .filter((call) => call.active == 0)
            .map((call) => ({
              ...call,
              amount: parseFloat(call.amount.replace(",", "")),
            }));
          setCallLogs(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function getOtherAccountByID(id) {
    if (loginSession.length != 0) {
      axios
        .post(
          `${config.host}:${config.port}/api/v2/sales_accounts/accounts_by_id`,
          {
            user: loginSession.username,
            id: id,
          }
        )
        .then(function (response) {
          let data = response.data;
          setAccountAccount(data[0]);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function addCallLogs(data, actions) {
    if (loginSession.length != 0) {
      console.log(data);
      axios
        .post(
          `${config.host}:${config.port}/api/v2/sales_accounts/add_callLogs`,
          {
            user: loginSession.user,
            // account_id: data.amount,
            client_id: data.client_id,
            call_date: new Date(),
            received_by: user.full_name,
            amount: data.amount,
            remarks: data.remarks,
            notes: data.notes.replace(/'/g, ""),
            account_executive: data.account_executive,
            modify_date: new Date(),
            modify_by: loginSession.username,
            call_status: data.status,
          }
        )
        .then(function (response) {
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function updateCallLogs(data, actions) {
    if (loginSession.length != 0) {
      // console.log(data);
      axios
        .post(
          `${config.host}:${config.port}/api/v2/sales_accounts/update_callLogs`,
          {
            id: data.id,
            user: loginSession.user,
            amount: data.amount,
            remarks: data.remarks,
            notes: data.notes.replace(/'/g, ""),
            modify_date: new Date(),
            modify_by: loginSession.username,
            call_status: data.status,
          }
        )
        .then(function (response) {
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function deleteCallLogs(data, actions) {
    if (loginSession.length != 0) {
      // console.log(data);
      axios
        .post(
          `${config.host}:${config.port}/api/v2/sales_accounts/delete_callLogs`,
          {
            id: data.id,
          }
        )
        .then(function (response) {
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function updateAccount(data, actions) {
    if (loginSession.length != 0) {
      // console.log(data);
      axios
        .post(
          `${config.host}:${config.port}/api/v2/sales_accounts/update_profile`,
          {
            id: data.id,
            client_id: data.client_id,
            account_holder: data.account_holder,
            address: data.address,
            contact_person: data.contact_person,
            mobile_phone: data.mobile_phone,
            email_address: data.email_address,
            nature_of_business: data.nature_of_business,
            particulars: data.particulars,
            terms: data.terms,
            price_class: data.price_class,
            active: data.active,
            tin_id: data.tin_id,
            ae_name: data.ae_name,
            operating_hours: data.operating_hours,
          }
        )
        .then(function (response) {
          actions();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function addAccount(data, actions) {
    if (loginSession.length != 0) {
      // console.log(data);
      axios
        .post(
          `${config.host}:${config.port}/api/v2/sales_accounts/add_profile`,
          {
            client_id: data.client_id,
            account_holder: data.account_holder || "",
            address: data.address || "",
            contact_person: data.contact_person || "",
            mobile_phone: data.mobile_phone || "",
            email_address: data.email_address || "",
            nature_of_business: data.nature_of_business || "",
            particulars: data.particulars || "",
            terms: data.terms || "",
            price_class: data.price_class || "",
            active: data.active,
            tin_id: data.tin_id || "",
            ae_name: data.ae_name || "",
            operating_hours: data.operating_hours || "",
          }
        )
        .then(function (response) {
          // console.log(response);
          actions();
          window.location.replace(
            "/AMS_sales/Account?id=" + response?.data.insertId
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    getOtherAccounts();
    getTelemarketerOA();
  }, []);

  return {
    todayScheduled,
    getScheduledToday,
    telemarketerOA,
    otherAccounts,
    otherAccount,
    getOtherAccountByID,
    callLogs,
    getOtherAccountCallByID,
    addCallLogs,
    updateCallLogs,
    updateAccount,
    deleteCallLogs,
    addAccount,
  };
}

export default useOtherAccounts;
