module.exports = {
  host: "http://192.168.1.12",
  port: 5500,
  CURRENT_YEAR: 2024,
  API: {
    DIRECTORY: {
      getAppList: "",
      getUsers: "",
      getPreferences: "",
      getVariants: "",
      getEmployees: "",
    },
    AMSOA: {
      getClients: "",
      getOrders: "",
      getCallLogs: "",
    },
    AMS711: {
      get711Stores: "",
      get711Calls: "",
      getCallLogs: "",
      getReminderList: "",
    },
    SHOPEE: {
      getOrders: "",
      getPaymentRelease: "",
    },
    LAZADA: {
      getOrders: "",
      getPaymentRelease: "",
    },
  },
};
